<template>
  <TransitionGroup
    tag="div"
    name="notification-list"
    class="fixed top-[5px] right-[13%] z-[99991] w-[390px] mobile:top-0 mobile:right-0 mobile:left-0 mobile:w-full block mobile:hidden"
  >
    <div
      class="notification-stack-container relative"
      :class="{ 'h-0': !visiblePopupNotifications.length, 'h-[90px]': visiblePopupNotifications.length }"
      :key="'stack-container'"
    >
      <NotificationItem
        v-for="(notification, index) in visiblePopupNotifications"
        :key="notification.id"
        :notification="notification"
        :isPopup="true"
        :style="getNotificationStyle(index)"
        class="notification-item bg-[#fff]/95 absolute top-0 left-0 w-full transition-all duration-300"
      />
    </div>
  </TransitionGroup>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useNotificationStore } from "@/stores/notificationStore";
import { useUserStore } from "@/stores/userStore";
import NotificationItem from "./NotificationItem.vue";
import { shouldPlaySound } from "@/helpers/notificationHelper";
import { playCloseNotificationSound } from "@/helpers/soundHelper";
import { shouldShowNotification } from "@/helpers/notificationHelper";

const notificationStore = useNotificationStore();
const userStore = useUserStore();

let hideTimeout = null;
let lastActivityTime = Date.now();

const visiblePopupNotifications = computed(() => {
  const userId = userStore.user?.id;
  return notificationStore.popupNotifications
    .filter((notification) => shouldShowNotification(notification, userId))
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
});

const getNotificationStyle = (index) => {
  const baseStyle = {
    zIndex: 1000 - index,
    position: "absolute",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  };

  if (index === 0) {
    baseStyle.top = "0px";
  } else {
    const stackIndex = index - 1;
    baseStyle.transform = `translate(${stackIndex * 2}px, ${stackIndex * 2}px)`;
    baseStyle.opacity = Math.max(1 - stackIndex * 0.2, 0.5);
  }

  return baseStyle;
};

const resetHideTimeout = () => {
  clearTimeout(hideTimeout);
  hideTimeout = setTimeout(() => {
    if (Date.now() - lastActivityTime > 5000) {
      notificationStore.closeAllPopupNotifications();
    }
  }, 5000);
};

const onUserActivity = () => {
  lastActivityTime = Date.now();
  resetHideTimeout();
};

onMounted(() => {
  window.addEventListener("mousemove", onUserActivity);
  window.addEventListener("keydown", onUserActivity);

  watch(
    visiblePopupNotifications,
    (newValue) => {
      if (newValue.length > 0) {
        resetHideTimeout();
        const latestNotification = newValue[0];
        if (shouldPlaySound(latestNotification, userStore.user?.id)) {
          playCloseNotificationSound();
        }
      }
    },
    { immediate: true }
  );
});

onUnmounted(() => {
  window.removeEventListener("mousemove", onUserActivity);
  window.removeEventListener("keydown", onUserActivity);
  clearTimeout(hideTimeout);
});
</script>

<style scoped>
.notification-stack-container {
  perspective: 1000px;
  transition: height 0.3s ease;
}

.notification-item {
  backface-visibility: hidden;
  transform-origin: top center;
}

.notification-list-enter-active,
.notification-list-leave-active {
  transition: all 0.5s ease;
}

.notification-list-leave-to,
.notification-list-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.notification-list-move {
  transition: transform 0.5s ease;
}
</style>
