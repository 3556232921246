<template>
  <div class="fixed inset-0 bg-[#f1f1f1] z-[99991] text-black">
    <div class="flex items-center justify-between p-4 bg-[#fc7a78]">
      <div
        class="flex items-center justify-center gap-4 w-full relative text-white"
      >
        <button class="p-2 absolute left-0" @click="$emit('close')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#fff"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <h1 class="text-[20px]">Уведомления</h1>
      </div>
    </div>

    <div class="border-b border-[#797979]/80">
      <div
        class="flex gap-4 px-4 overflow-x-auto custom-scrollbar text-nowrap flex-nowrap text-[14px]"
      >
        <button
          v-for="tab in tabs"
          :key="tab.id"
          @click="activeTab = tab.id"
          class="py-2 relative"
          :class="{ 'text-[#fc7a78]': activeTab === tab.id }"
        >
          {{ tab.name }}
        </button>
      </div>
    </div>

    <div class="h-[calc(100vh-120px)] overflow-y-auto custom-scrollbar">
      <NotificationItem
        v-for="(notification, index) in visiblePopupNotifications"
        :key="notification.id"
        :notification="notification"
        :isPopup="false"
        class="border-b border-[#797979]/80"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import NotificationItem from "./NotificationItem.vue";

const props = defineProps({
  visiblePopupNotifications: {
    type: Array,
    required: true,
  },
});

defineEmits(["close"]);

const activeTab = ref("all");
const tabs = [
  { id: "all", name: "Все", count: 133 },
  { id: "system", name: "Уведомления системы", count: 24 },
  { id: "recent", name: "Последние события", count: null },
];
</script>

<style scoped>
.notification-list-enter-active,
.notification-list-leave-active {
  transition: all 0.5s ease;
}

.notification-list-leave-to,
.notification-list-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.notification-list-move {
  transition: transform 0.5s ease;
}
</style>
