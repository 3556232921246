<template>
  <div>
    <header
      class="py-[12px] mobile:py-[7px] px-4 text-white bg-[#fc7a78] mobile:relative"
    >
      <div
        class="container mx-auto !max-w-[1340px] flex justify-between max-[640px]:py-0 items-center"
      >
        <div class="flex items-center justify-between max-[768px]:w-full">
          <button @click="toggleMenu" class="hidden mr-4 mobile:block">
            <BurgerMenu />
          </button>
          <div
            @click="goToHome"
            class="flex items-center cursor-pointer gap-[6px]"
            :class="{
              'mobile:absolute mobile:left-[45%] mobile:right-[45%]': user,
            }"
          >
            <FinalLogo
              class="h-[40px] w-[54px] mobile:w-[40px] mobile:h-[30px]"
            />
          </div>
        </div>
        <div
          class="flex items-center justify-center mobile:justify-end gap-[25px] mobile:gap-[15px]"
        >
          <EntryBlock v-if="!user" />
          <template v-else>
            <div
              @click="toggleMobileNotifications"
              class="flex flex-col items-center cursor-pointer justify-center"
            >
              <Notification />
              <span class="text-[9px] mobile:hidden custom-shadow"
                >Уведомления</span
              >
            </div>
            <ProfileMenu @go-to-profile="goToProfile" @logout="handleLogout" />
          </template>
        </div>
      </div>
      <NotificationPopup v-if="user && !isMobile" />

      <Teleport to="body">
        <NotificationPopupMobile
          v-if="user && isMobileNotificationsOpen"
          :visible-popup-notifications="visiblePopupNotifications"
          @close="closeMobileNotifications"
        />
      </Teleport>
      <NotificationList v-if="user" ref="notificationList" />
    </header>

    <Teleport to="body">
      <Transition name="fade">
        <div
          v-if="isMenuOpen"
          class="fixed inset-0 bg-gray-900 bg-opacity-50 z-[10000]"
          @click="closeMenu"
        >
          <div class="bg-white mobile:w-[80%] mobile:h-full" @click.stop>
            <ArticleComponent
              @navigation-event="closeMenu"
              @update-user="handleUserUpdate"
              :hide-balance="hideBalance"
              :formatted-total-balance="calculateTotalBalance"
              @toggle-balance="toggleBalance"
            />
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useNavigationStore } from "../stores/marketStore";
import { useUserStore } from "@/stores/userStore";
import EntryBlock from "../components/auth/EntryBlock.vue";
import BurgerMenu from "../components/icons/BurgerMenu.vue";
import ProfileMenu from "../blocks/ProfileMenu.vue";
import Notification from "@/components/icons/Notification.vue";
import NotificationList from "../blocks/NotificationList.vue";
import NotificationPopup from "../blocks/NotificationPopup.vue";
import NotificationPopupMobile from "../blocks/NotificationPopupMobile.vue";
import FinalLogo from "@/components/icons/FinalLogo.vue";
import ArticleComponent from "../blocks/Article.vue";

const router = useRouter();
const navigationStore = useNavigationStore();
const userStore = useUserStore();

const { user } = storeToRefs(userStore);
const isMobileNotificationsOpen = ref(false);
const isMobile = ref(window.innerWidth < 640);
const isMenuOpen = ref(false);
const notificationList = ref(null);
const hideBalance = ref(false);

const calculateTotalBalance = computed(() => {
  if (!user.value?.balance) return "0";
  const total = Object.values(user.value.balance).reduce(
    (sum, value) => sum + (Number(value) || 0),
    0
  );
  return total.toFixed(0);
});

watch(
  () => router.currentRoute.value,
  () => {
    closeMenu();
  }
);

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}

function toggleNotificationList() {
  if (notificationList.value) {
    notificationList.value.toggleVisibility();
  }
}

function toggleBalance() {
  hideBalance.value = !hideBalance.value;
}

function handleLogout() {
  userStore
    .logout()
    .then(() => {
      navigationStore.showMain();
      closeMenu();
    })
    .catch((error) => {
      console.error("Logout error:", error);
    });
}

function handleUserUpdate() {
  return userStore.fetchUser().catch((error) => {
    console.error("User update error:", error);
  });
}

function goToHome() {
  router.push({ name: "landing" });
  closeMenu();
}

function goToProfile() {
  navigationStore.showUserProfile();
  closeMenu();
}

function closeMenu() {
  isMenuOpen.value = false;
}

function toggleMobileNotifications() {
  if (isMobile.value) {
    isMobileNotificationsOpen.value = !isMobileNotificationsOpen.value;
  } else {
    toggleNotificationList();
  }
}

function closeMobileNotifications() {
  isMobileNotificationsOpen.value = false;
}

function handleResize() {
  isMobile.value = window.innerWidth < 640;
}

onMounted(() => {
  document.addEventListener("keydown", handleEscKey);
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  document.removeEventListener("keydown", handleEscKey);
  window.removeEventListener("resize", handleResize);
});

function handleEscKey(event) {
  if (event.key === "Escape") {
    if (isMenuOpen.value) {
      closeMenu();
    }
    if (isMobileNotificationsOpen.value) {
      closeMobileNotifications();
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
